<template>
	<div>
		<!-- 公司业务板块 -->
		<div class="home_class">
			<div class="yewu_class">
				<img class="yewu_img" src="../../assets/images/home_images/yewu.png" alt="">
				<div class="yewu_text" v-if="data.business">{{data.business.description}}</div>
				<!-- <el-carousel type="card" :height="bannerHeight + 'px'" :autoplay="false" -->
				<el-carousel type="card" height="376px" :autoplay="false" indicator-position="none"
					@change="yuwuChange">
					<el-carousel-item v-for="(item,index) in businessList" :key="index">
						<img :src="item.imagePath" alt="" ref="imgRef" style="width: 100%;">
					</el-carousel-item>
				</el-carousel>
				<div class="yewu_text2">
					{{businessData.keywords}}
				</div>
				<div class="yewu_text3">
					{{businessData.description}}
				</div>
				<!-- 动态功能 -->
				<div class="yewu_gongneng">
					<div class="yewu_gongneng_flag">
						<div class="yewu_gongneng_1" v-for="(item,index) in businessData.children" :key="index">
							<img :src="item.imagePath" alt="">
							<div class="yewu_gongneng_1_title">
								{{item.keywords}}
							</div>
							<div class="yewu_gongneng_1_content">
								{{item.description}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="width: 100%;height: 20px; background-color: #FFFFFF;"></div>
		<!-- 新闻资讯板块 -->
		<div class="news_class">
			<img src="../../assets/images/home_images/xinwen.png" alt="" style="margin-top: 71px;">
			<div class="news_text">记录团队成长点滴以及对技术、理念的探索，学习，成长，分享，共建。</div>
			<!-- 新闻内容 -->
			<div class="news_centent_father">
				<div class="news_centent newActiveClass" v-for="(item,index) in newList" :key="index"
					@click="goRouter(item.id)">
					<div class="news_centent_top">{{item.title}}</div>
					<div class="news_centent_bottom">{{$getDate(item.createTime)}}</div>
				</div>
			</div>
		</div>
		<div style="width: 100%;height: 20px; background-color: #FFFFFF;"></div>
		<!-- 合作伙伴 -->
		<div class="hezuo_class">
			<img src="../../assets/images/home_images/hezuo.png" alt="" style="margin-top: 70px;">
			<div class="hezuo_text">{{partners.description}}</div>
			<div class="hezuo_content">
				<img :src="item.path" alt="" v-for="(item,index) in partnerLinks" :key="index"
					:class="index % 5 == 4 ? 'abc':''">
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getData,
		getBusiness,
		getNews
	} from '../../api/home.js'
	export default {
		data() {
			return {
				newsAcitve: 0,
				data: {},
				// 合作伙伴简介
				partners: {},
				// 合作伙伴图片
				partnerLinks: [],
				// 图片父容器高度
				bannerHeight: "",
				// 公司业务
				businessList: [],
				businessData: {},
				// 新闻资讯列表
				newList: [],
				// 浏览器宽度
				screenWidth: 0,
			}
		},
		mounted() {
			this.getData()
			this.getBusiness()
			this.getNews()
		},
		methods: {
			yuwuChange(index) {
				this.businessData = this.businessList[index]
			},
			getData() {
				getData().then(res => {
					this.data = res.result
					this.partners = res.result.partners
					this.partnerLinks = res.result.partnerLinks
					this.otherWebs = res.result.otherWebs
				})
			},
			getBusiness() {
				getBusiness().then(res => {
					this.businessList = res.result
					this.businessData = this.businessList[0]
				})
			},
			getNews() {
				const data = {
					page: 1,
					pageSize: 4,
					code: "data_news"
				}
				getNews(data).then(res => {
					this.newList = res.result.list
				})
			},
			// 跳转到新闻详情
			goRouter(id) {
				//跳转时页面
				this.$router.push({
					path: '/bigTower/content',
					query: {
						id
					}
				})
			}
		},
		components: {}
	}
</script>
<style lang="less" scoped>
	.home_class {
		width: 1200px;
		margin: 0 auto;

		.yewu_class {
			margin-top: 90px;

			.yewu_img {
				width: 100%;
				height: auto;
			}

			.yewu_text {
				text-align: center;
				width: 100%;
				height: 34px;
				margin-bottom: 72px;
				font-size: 22px;
				font-family: MicrosoftYaHei;
				line-height: 26px;
				color: #666666;
				opacity: 1;
			}

			.yewu_text2 {
				height: 40px;
				font-size: 28px;
				font-family: MicrosoftYaHei;
				line-height: 34px;
				color: #000000;
				opacity: 1;
				text-align: center;
				margin-top: 34px;
			}

			.yewu_text3 {
				text-align: center;
				height: 50px;
				font-size: 16px;
				font-family: MicrosoftYaHei;
				line-height: 24px;
				color: #666666;
				opacity: 1;
				margin-top: 11px;
			}

			.el-carousel {
				height: auto;
			}

			.yewu_gongneng {
				width: 100%;
				height: 400px;
				margin-top: 73px;
				margin-bottom: 80px;

				.yewu_gongneng_flag {
					display: flex;
					justify-content: space-between;
					height: 100%;

					.yewu_gongneng_1,
					.yewu_gongneng_2 {
						width: 340px;
						height: 100%;
						text-align: center;
						background-color: #FFFFFF;

						img {
							margin-top: 60px;
						}

						.yewu_gongneng_1_title {
							font-size: 28px;
							font-family: MicrosoftYaHei;
							color: #000000;
							opacity: 1;
							margin-top: 34px;
							margin-bottom: 15px;
						}

						.yewu_gongneng_1_content {
							font-size: 16px;
							font-family: MicrosoftYaHei;
							color: #666666;
							opacity: 1;
							line-height: 24px;
							margin-left: 40px;
							margin-right: 40px;
							text-align: left;
						}
					}
				}

			}
		}

		.el-carousel__item:nth-child(2n) {
			background-color: #99a9bf;
		}

		.el-carousel__item:nth-child(2n+1) {
			background-color: #d3dce6;
		}
	}

	.news_class {
		width: 1200px;
		margin: 0 auto;

		.news_text {
			width: 1200px;
			height: 30px;
			font-size: 22px;
			font-family: MicrosoftYaHei;
			line-height: 26px;
			color: #666666;
			opacity: 1;
			text-align: center;
		}

		.news_centent_father {
			width: 100%;
			height: 200px;
			margin-top: 76px;
			margin-bottom: 80px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: space-between;

			.news_centent {
				width: 560px;
				height: 80px;
				border-left: 10px solid #999999;
				box-sizing: border-box;
				padding-left: 40px;
				cursor: pointer;
				background-color: #FFFFFF;

				.news_centent_top {
					width: 458px;
					height: 29px;
					margin-bottom: 7px;
					margin-top: 16px;
					font-size: 22px;
					font-family: MicrosoftYaHei;
					line-height: 26px;
					color: #333333;
					opacity: 1;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.news_centent_bottom {
					height: 21px;
					font-size: 16px;
					font-family: MicrosoftYaHei;
					line-height: 19px;
					color: #999999;
					opacity: 1;
				}
			}

			.news_centent:hover {
				border-left: 10px solid #E75513 !important;
			}
		}
	}

	.hezuo_class {
		width: 1200px;
		margin: 0 auto;

		.hezuo_text {
			height: 32px;
			font-size: 22px;
			font-family: MicrosoftYaHei;
			line-height: 26px;
			color: #666666;
			opacity: 1;
			text-align: center;
		}

		.hezuo_content {
			width: 100%;
			padding-bottom: 40px;
			margin-top: 74px;

			img {
				width: 200px;
				height: 100px;
				margin-right: 50px;
				margin-bottom: 40px;
			}

			.abc {
				margin-right: 0;
			}
		}
	}

	.home_class .yewu_class /deep/ .is-active {
		width: 940px !important;
		height: 376px !important;
		transform: translateX(130px) scale(1) !important;
	}

	.yewu_class /deep/ .is-in-stage img {
		height: 100%;
	}
	.yewu_class /deep/ .el-carousel__arrow--left {
		width: 80px !important;
		height: 80px !important;
		background: url(../../assets/images/home_images/left_arrows.png);
		background-repeat: no-repeat;
		background-size: 100%;
	}
	.yewu_class /deep/ .el-carousel__arrow--right {
		width: 80px !important;
		height: 80px !important;
		background: url(../../assets/images/home_images/right_arrows.png);
		background-repeat: no-repeat;
		background-size: 100%;
	}
	
	
	.yewu_class /deep/ .el-carousel__arrow i {
		display: none !important;
	}
	.yewu_class /deep/ .is-in-stage:second {
		height: 200px !important;
	} 
	.yewu_class /deep/ .el-carousel__container:nth-child(4) {
		height: 200px !important;
	}
</style>
